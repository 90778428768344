<template>
    <div class="live_box" :style="{ '--themecolor': themecolors }">
        <div class="box" id="scroll">
            <div style="min-height:95%;">
                <div class="top img-center" v-if="cover_status == 1">
                    <img :src="cover_url" alt="">
                </div>
                <div class="bottom">
                    <div class="tabs" v-if="library_list.length > 0"
                        :style="library_list.length > 3 ? arrow_up ? 'height:auto;padding-bottom:.2rem;' : 'height:.6rem' : 'height:.51rem'">
                        <div class="tab-box" v-for="(i, index) in library_list" :key="i.id"
                            :style="i.id == library_id ? `background:${themecolors};border:none;color:#fff;` : ''"
                            @click="library_id = i.id, cover_url = i.cover_url, get_library_live_studio_list(i.id, index)">
                            <div class="box-title" v-if="i.id != library_id">{{ i.title }}</div>
                            <!-- <marquee class="box-title scroll-box" behavior="alternate" loop="2" direction="left" scrollamount="1" v-if="i.id == library_id">{{ i.title }}</marquee> -->
                            <div class="box-title scroll-box" v-if="i.id == library_id">
                                <p>{{ i.title }}</p>
                            </div>
                        </div>
                        <span class="arrow-up" @click="arrow_up = !arrow_up" v-show="library_list.length > 3">
                            <!-- <van-icon :name="arrow_up ? 'arrow-up' : 'arrow-down'"/> {{!arrow_up ? '展开' : '收起'}} -->
                            <img src="@/assets/arrow_up.svg" alt="" :style="!arrow_up ? 'transform:rotate(180deg)' : ''"
                                style="width:.13rem;">
                        </span>
                    </div>
                    <div class="bottom-content">
                        <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading" />
                        <van-empty class="list-null" :image="require('@/assets/null.svg')"
                            v-if="library_list.length == 0" />
                        <van-empty :image="require('@/assets/null.svg')"
                            v-if="live_list.length == 0 && !Loading && library_list.length > 0" />
                        <div class="live-data" v-for="(item, index) in live_list" :key="index + 0.3" v-else>
                            <div class="title-box" v-if="display_mode == 2">
                                <img src="../assets/icon/live-left.svg" alt="" class="img-left">
                                <span>{{ item.file_title }}</span>
                                <img src="../assets/icon/live-right.svg" alt="" class="img-right">
                            </div>
                            <div class="live-box" v-if="display_mode == 2">
                                <div class="live-list" v-for="(val, i) in item.live_list" :key="i"
                                    @click="routeSkip(val)">
                                    <img :src="val.img_player_url" class="live-left" alt="" v-if="studio_mode == 1">
                                    <div class="time-left" v-if="studio_mode == 2">
                                        <span>{{ val.studioTime }}</span>
                                    </div>
                                    <div class="live-title" v-if="studio_mode != 3">
                                        <p class="studio_title main-title">{{ val.studio_title }}</p>
                                        <p class="studio_title">{{ val.place_title }}</p>
                                        <span class="start_at">{{ val.start_at }}</span>
                                    </div>
                                    <div class="live-title modeThree" v-else style="max-width: 80%">
                                        <p class="studio_title">{{ val.studio_title }}</p>
                                        <div class="title-date">
                                            <p class="start_at">{{ val.place_title }}</p>
                                            <span class="start_at">{{ val.start_at }}</span>
                                        </div>
                                    </div>
                                    <div class="enter-box">
                                        <!-- <div class="live-enter" :class="{'live-finish': val.live_studio=='回放', 'live-color': val.live_studio=='直播'}">{{ val.live_studio }}</div> -->
                                        <div class="live-enter"
                                            :class="{ 'live-replay': val.live_studio == '回放', 'live-finish': val.live_studio == '结束', 'live-color': val.live_studio == '直播', 'live-closure': val.live_studio == '关闭' }">
                                            {{ val.live_studio }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="single-box" v-else>
                                <div class="live-list" @click="routeSkip(item)">
                                    <img :src="item.img_player_url" class="live-left" alt="" v-if="studio_mode == 1">
                                    <div class="time-left" v-if="studio_mode == 2">
                                        <span>{{ item.studioTime }}</span>
                                    </div>
                                    <div class="live-title" v-if="studio_mode != 3">
                                        <p class="studio_title main-title">{{ item.studio_title }}</p>
                                        <p class="studio_title">{{ item.place_title }}</p>
                                        <span class="start_at">{{ item.start_at }}</span>
                                    </div>
                                    <div class="live-title modeThree" v-else style="max-width: 80%">
                                        <p class="studio_title main-title">{{ item.studio_title }}</p>
                                        <div class="title-date">
                                            <p class="start_at">{{ item.place_title }}</p>
                                            <span class="start_at">{{ item.start_at }}</span>
                                        </div>
                                    </div>
                                    <div class="enter-box">
                                        <!-- <div class="live-enter" :class="{'live-finish': item.live_studio=='回放', 'live-color': item.live_studio=='直播'}">{{ item.live_studio }}</div> -->
                                        <div class="live-enter"
                                            :class="{ 'live-replay': item.live_studio == '回放', 'live-finish': item.live_studio == '结束', 'live-color': item.live_studio == '直播', 'live-closure': item.live_studio == '关闭' }">
                                            {{ item.live_studio }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Support></Support>
        </div>
        <sideBar></sideBar>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import sideBar from '@/components/sidebar';
import Support from '@/components/support';
export default {
    components: {
        sideBar,
        Support
    },
    data() {
        return {
            library_list: [],
            library_id: null,
            Loading: false,
            live_title: [1, 2, 3],
            live_list: [],
            params: {},
            cover_url: "",
            oldid: null,
            arrow_up: true,
            showSideBar: true,
            cover_status: '', //kv图显示隐藏 1显示 0不显示
            display_mode: '',//直播列表式2分组显示 1不分组
            studio_mode: '',//直播间封面  1图片显示  2显示时间  3不显示
        }
    },
    created() {
        // if(this.$route.query.site_url) {
        //     this.showSideBar = false
        // }
        new Date()
        this.params = this.$route.query;
        this.get_library_live_list();
    },
    computed: {
        ...mapState(['themecolors'])
    },
    methods: {
        get_library_live_list() {
            this.Loading = true;
            this.$store.dispatch('get_library_live_list', { site_id: this.params.site_id, module_id: this.params.module_id }).then(res => {
                this.Loading = false;
                if (res.data.code == 200) {
                    document.title = res.data.data.module_title ? res.data.data.module_title : (sessionStorage.getItem('wei-title') || '轻微站');
                    this.library_list = res.data.data.module_content;
                    const todeday = new Date(new Date().toLocaleDateString()).getTime()
                    const endday = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1
                    this.library_list.forEach((item) => {
                        if (item.type == '1') {
                            var now = new Date(item.title);
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            var date = now.getDate();
                            var hour = now.getHours();
                            item.title = year + "年" + month + "月" + date + "日"
                        }
                    })
                    let index = res.data.data.module_content.findIndex(item => todeday <= Number(item.timestamp) * 1000 && Number(item.timestamp) * 1000 <= endday)
                    if (index == -1) {
                        index = 0
                    }
                    if (index > 3) {
                        this.arrow_up = true
                    }
                    if (this.library_list[index]) {
                        this.library_id = this.library_list[index].id;
                        this.cover_url = this.library_list[index].cover_url;
                        this.cover_status = this.library_list[index].cover_status;
                        this.display_mode = this.library_list[index].display_mode
                        this.studio_mode = this.library_list[index].studio_mode
                    }
                    this.get_library_live_studio_list(this.library_id, index)
                }
            })
        },
        get_library_live_studio_list(id, index) {
            if (this.library_id == this.oldid) {
                return
            }
            // 文字跑马灯
            this.$nextTick().then(() => {
                let scroll = document.querySelector(".scroll-box")
                let p = document.querySelector(".scroll-box p")
                if (p.clientWidth > scroll.clientWidth) {
                    if (p.clientWidth < 150) {
                        scroll.classList.add("scroll-box-active");
                    } else if (p.clientWidth < 240) {
                        scroll.classList.add("scroll-box-actives");
                    } else {
                        scroll.classList.add("scroll-box-activers");
                    }
                }
            })
            this.library_id = this.library_list[index].id;
            this.cover_url = this.library_list[index].cover_url;
            this.cover_status = this.library_list[index].cover_status;
            this.display_mode = this.library_list[index].display_mode
            this.studio_mode = this.library_list[index].studio_mode
            this.Loading = true;
            this.live_list = [];
            this.$store.dispatch('get_library_live_studio_list', { id: id, module_id: this.params.module_id }).then(res => {
                this.Loading = false;
                if (res.data.code == 200) {
                    res.data.data.forEach(item => {
                        if (this.display_mode == 2) {
                            if (Array.isArray(item.live_list)) {
                                item.live_list.forEach(val => {
                                    if (this.studio_mode == 2) {
                                        let H = ''
                                        let M = ''
                                        if (new Date(val.start_at.replace(/-/g, "/")).getHours() < 10) {
                                            H = '0' + ('' + new Date(val.start_at.replace(/-/g, "/")).getHours())
                                        } else {
                                            H = new Date(val.start_at.replace(/-/g, "/")).getHours()
                                        }
                                        if (new Date(val.start_at.replace(/-/g, "/")).getMinutes() < 10) {
                                            M = '0' + ('' + new Date(val.start_at.replace(/-/g, "/")).getMinutes())
                                        } else {
                                            M = new Date(val.start_at.replace(/-/g, "/")).getMinutes()
                                        }
                                        val.studioTime = H + ' ' + " : " + ' ' + M
                                    }
                                    // if(val.type == 2){
                                    //     val.img_player_url = 'https://images.weserv.nl/?url=' + val.img_player_url.substring(7)
                                    // }
                                    if (val.studio_status == 0) {
                                        val.live_studio = '预告'
                                    } else if (val.studio_status == 1) {
                                        val.live_studio = '直播'
                                    } else if (val.studio_status == 2) {
                                        val.live_studio = '结束'
                                    } else if (val.studio_status == 3) {
                                        val.live_studio = '回放'
                                    } else {
                                        val.live_studio = '关闭'
                                    }
                                })
                            }
                        } else {
                            if (this.studio_mode == 2) {
                                let H = ''
                                let M = ''
                                if (new Date(item.start_at.replace(/-/g, "/")).getHours() < 10) {
                                    H = '0' + ('' + new Date(item.start_at.replace(/-/g, "/")).getHours())
                                } else {
                                    H = new Date(item.start_at.replace(/-/g, "/")).getHours()
                                }
                                if (new Date(item.start_at.replace(/-/g, "/")).getMinutes() < 10) {
                                    M = '0' + ('' + new Date(item.start_at.replace(/-/g, "/")).getMinutes())
                                } else {
                                    M = new Date(item.start_at.replace(/-/g, "/")).getMinutes()
                                }
                                item.studioTime = H + ' ' + " : " + ' ' + M
                            }
                            if (item.studio_status == 0) {
                                item.live_studio = '预告'
                            } else if (item.studio_status == 1) {
                                item.live_studio = '直播'
                            } else if (item.studio_status == 2) {
                                item.live_studio = '结束'
                            } else if (item.studio_status == 3) {
                                item.live_studio = '回放'
                            } else {
                                item.live_studio = '关闭'
                            }
                        }
                    })
                    this.live_list = res.data.data;
                    this.oldid = id;
                }
            })
        },
        routeSkip(item) {
            if (item.type == 1) {
                let obj = {
                    studio_id: item.studio_id,
                    site_id: this.params.site_id,
                }
                this.Loading = true;
                this.$store.dispatch("get_user_form_identity", obj).then(res => {
                    this.Loading = false;
                    if (res.data.code == 200) {
                        this.$cookies.set('microtoken', res.data.token)
                        location.href = item.live_studio_url + '?liguserinfo=' + res.data.data.token;
                    }
                    if (res.data.code == 206) {
                        location.href = item.live_studio_url;
                    }
                }).catch(err => {
                    location.href = item.live_studio_url;
                })
            } else {
                location.href = item.live_studio_url;
            }
        },
        getLocalTime(time) {
            if (time.type == '1') {
                var now = new Date(time.title)
                var year = now.getFullYear();
                var month = now.getMonth() + 1;
                var date = now.getDate();
                var hour = now.getHours();
                // var minute=now.getMinutes(); 
                // var second=now.getSeconds(); 
                return year + "年" + month + "月" + date + "日"
            } else {
                return time.title
            }

            // +hour+":"+minute+":"+second;   
        },
    }
}
</script>

<style lang="less" scoped>
@Color: var(--themecolor);

.flex-center {
    display: flex;
    align-items: center;
}

.between {
    justify-content: space-between;
}

.img-center {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

/deep/.van-empty,
/deep/.van-loading {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

/deep/ .list-null {
    top: 50%;
    transform: translate(-50%, -50%);
}

/deep/.van-empty {
    width: 100%;

    .van-empty__image {
        width: 3rem;
        height: 3rem;
    }
}

/deep/.van-loading {
    top: 60%;
}

.box {
    width: 100%;
    background: #F7F8FA;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: scroll;

    .top {
        width: 100%;
        height: 2.1rem;
        overflow: hidden !important;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }
    }

    .bottom {
        width: 100%;

        .tabs {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-between;
            // display: -webkit-box;
            // overflow-x: scroll;
            // overflow-y:hidden;
            // white-space:nowrap;
            // text-align: center;
            background: #fff;
            padding: .05rem 0.05rem .05rem;

            // cursor: pointer;
            // position: sticky;
            // top:0;
            box-shadow: 0 .02rem .08rem #F7F8FA;
            // &::-webkit-scrollbar{
            //     display: none;
            // }
            overflow: hidden;
            position: relative;

            .tab-box {
                height: .32rem;
                // line-height: .3rem;
                // display: block;
                // padding: .07rem 0;
                // width: auto;
                width: 32%;
                padding: 0 0.1rem;
                text-align: center;
                font-size: .12rem;
                color: #646566;
                border-radius: .32rem;
                border: .01rem solid #EBEDF0;
                // margin: .05rem 0;
                // min-width: 114px;
                margin: 0.05rem 0.02rem;
                overflow: hidden;
                box-sizing: border-box;
                display: flex;
                align-items: center;

                // flex: 1;
                .box-title {
                    display: inline-block;
                    // height: 0.32rem;

                    white-space: nowrap;
                    width: 100%;
                    overflow: hidden;
                    overflow-x: scroll;
                    height: .19rem;
                    line-height: 0.19rem;

                    p {
                        display: inline-block;
                    }

                    &::-webkit-scrollbar {
                        height: 0;
                        width: 0;
                    }

                    &::-webkit-scrollbar-thumb {
                        width: 0;
                        border-radius: .1rem;
                        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                        background-color: #555;
                    }
                }

                .scroll-box {
                    &::-webkit-scrollbar {
                        display: none;
                    }

                    height: .17rem !important;
                }

                .scroll-box-active {

                    // color: red;
                    p {
                        animation: move 2.5s infinite alternate linear;
                    }
                }

                .scroll-box-actives {

                    // color: red;
                    p {
                        animation: moves 4s infinite alternate linear;
                    }
                }

                .scroll-box-activers {

                    // color: red;
                    p {
                        animation: movers 5s infinite alternate linear;
                    }
                }

            }

            .arrow-up {
                width: 95%;
                font-size: .12rem;
                position: absolute;
                left: .1rem;
                bottom: -0.04rem;
                color: #888;
                text-align: center;
                background: #fff;
            }
        }

        .live-data {
            .title-box {
                height: 0.53rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: .16rem;
                font-weight: 900;
                // color: @Color;
                color: #00ccb0;

                img {
                    display: block;
                    width: .2rem;
                    height: .12rem;
                }

                span {
                    margin: 0 .08rem;
                    max-width: calc(80% - .4rem);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }

            .live-box {
                .live_list {}

            }

            .live-list {
                background: #fff;
                // height: .9rem;
                margin: 0 .1rem;
                padding: .03rem .05rem;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                overflow: hidden;
                border-bottom: .01rem solid #F7F8FA;

                &:first-child {
                    border-radius: .1rem .1rem 0 0;
                    padding-top: .05rem;
                }

                &:last-child {
                    border-radius: 0 0 .1rem .1rem;
                    border: none;
                    padding-bottom: .05rem;
                }

                .live-left {
                    display: block;
                    width: 1.24rem;
                    height: .7rem;
                    border-radius: .06rem;
                    overflow: hidden;
                }

                .time-left {
                    width: 1.24rem;
                    height: .7rem;
                    border-radius: .1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    // background-color: rgba(0, 0, 0, .8);
                    background-color: #8f9daf;

                    span {
                        font-size: .22rem;
                        font-weight: 900;
                        color: #fff;
                    }
                }

                .live-title {
                    flex: 1;
                    font-size: .12rem;
                    height: .7rem;
                    display: flex;
                    overflow: hidden;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: flex-start;
                    margin-left: .1rem;

                    .studio_title {
                        margin: 0;
                        padding: 0;
                        font-size: .14rem;
                        color: #000;
                        width: 100%;
                        font-weight: 500;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .main-title {
                        font-weight: bold;
                        font-size: .15rem;
                    }

                    .start_at {
                        font-size: .14rem;
                        color: #999;
                    }
                }

                .modeThree {
                    .studio_title {
                        font-size: .15rem;
                    }

                    .title-date {
                        display: flex;

                        span {
                            margin-left: .2rem;
                        }
                    }
                }

                .enter-box {
                    width: .41rem;
                    // width: auto;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    margin-top: .32rem;
                }

                .live-enter {
                    font-size: .12rem;
                    color: #fff;
                    width: .41rem;
                    // width: auto;
                    height: 0.2rem;
                    line-height: .2rem;
                    text-align: center;
                    border-radius: .02rem;
                    background-color: #00ccb0;
                    cursor: pointer;
                    margin-right: .04rem;
                    padding: 0 .04rem;
                }

                .live-color {
                    background-color: #E75A50;
                }

                .live-finish {
                    background: #868686;
                }

                .live-replay {
                    background: #eb8722;
                }

                .live-closure {
                    background: #262626;
                }

            }

            .single-box {
                margin: .1rem 0;

                .live-list {
                    border: none;
                    border-radius: 0.06rem;
                }
            }
        }
    }
}

@keyframes move {
    0% {
        left: 0;
        transform: translate(0, 0);
    }

    100% {
        left: 100%;
        transform: translate(-20%, 0);
    }
}

@keyframes moves {
    0% {
        left: 0;
        transform: translate(0, 0);
    }

    100% {
        left: 100%;
        transform: translate(-30%, 0);
    }
}

@keyframes movers {
    0% {
        left: 0;
        transform: translate(0, 0);
    }

    100% {
        left: 100%;
        transform: translate(-70%, 0);
    }
}
</style>